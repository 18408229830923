import {API_BASE_URL} from "../constants/AppConstants";
import {auth} from "../FirebaseSetup";


export async function getData(url = '') {
    const response = await fetch(API_BASE_URL + url,  {  method: 'GET',
        headers: {
            'Authorization': await jwtToken()
        }});
    if (response.ok) {
        return response.json();
    } else {
        throw Error(`${response.status}  ${response.statusText}`);
    }
}


export async function postData(url = '', data = {}) {
    const response = await fetch(API_BASE_URL + url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await jwtToken()
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    }else {
        throw Error(`${response.status}  ${response.statusText}`);
    }
}

export async function deleteData(url = '') {
    const response = await fetch(API_BASE_URL + url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await jwtToken()
        },
    });
    if (response.ok) {
        return response.json();
    }else {
        throw Error(`${response.status}  ${response.statusText}`);
    }
}

export async function putData(url = '', data = {}) {
    const response = await fetch(API_BASE_URL + url, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': await jwtToken()
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        return response.json();
    }else {
        throw Error(`${response.status}  ${response.statusText}`);
    }
}


async function jwtToken() {
    return 'bearer ' + await auth.currentUser?.getIdToken();
}