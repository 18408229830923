import React from 'react';
import {Container} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import "../../styles/about_us.scss"

PageAboutUs.propTypes = {};

function PageAboutUs() {

    const {t} = useTranslation();


    return (
        <Container className="d-flex flex-column">
            <article className="d-flex flex-column text-start">
                <h2>{t('page.about_us.article0.title')}</h2>
                <p>{t('page.about_us.article0.part0')}</p>
                <p>{t('page.about_us.article0.part1')}</p>
                <p>{t('page.about_us.article0.part2')}</p>
                <p>{t('page.about_us.article0.part3')}</p>
                <span>{t('page.about_us.article0.part4')}</span>
                <ul>
                    <li>{t('page.about_us.article0.part4list.0')}</li>
                    <li>{t('page.about_us.article0.part4list.1')}</li>
                    <li>{t('page.about_us.article0.part4list.2')}</li>
                    <li>{t('page.about_us.article0.part4list.3')}</li>
                    <li>{t('page.about_us.article0.part4list.4')}</li>
                    <li>{t('page.about_us.article0.part4list.5')}</li>
                    <li>{t('page.about_us.article0.part4list.6')}</li>
                    <li>{t('page.about_us.article0.part4list.7')}</li>
                </ul>
            </article>
            <article className="text-start">
                <h5>{t('page.about_us.article1.title')}</h5>
                <b>{t('page.about_us.article1.sub_title')}</b>
                <ul>
                    <li>{t('page.about_us.article1.list.0')}</li>
                    <li>{t('page.about_us.article1.list.1')}</li>
                    <li>{t('page.about_us.article1.list.2')}</li>
                    <li>{t('page.about_us.article1.list.3')}</li>
                </ul>
                <p>{t('page.about_us.article1.part0')}</p>
            </article>
            <article className="mb-5">
                <h5>{t('page.about_us.article2.title')}</h5>
                <p>{t('page.about_us.article2.part0')}</p>
                <a href="mailto:info@gatschu.com">info@gatschu.com</a>
            </article>
        </Container>
    );
}

export default PageAboutUs;