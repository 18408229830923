import {getData, putData} from "./client";
import {MyComment} from "../interface/MyComment";

export async function getComment(id: string) : Promise<MyComment>{
    try{
        return await getData(`/comment/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function toggleDisableComment(id: string) : Promise<boolean>{
    try{
        return await putData(`/comment/disable-toggle/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function disableAllComments(userId: string) : Promise<boolean>{
    try{
        return await putData(`/comment/disable-all/${userId}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}