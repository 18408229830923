import {deleteData, getData, putData} from "./client";
import {MyUser} from "../interface/MyUser";

export async function getUser(id: string) : Promise<MyUser>{
    try{
        return await getData(`${baseUrl}/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function toggleDisableUser(id: string) : Promise<boolean>{
    try{
        return await putData(`${baseUrl}/disable-toggle/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function deleteUser() : Promise<void>{
    try {
        await deleteData(`${baseUrl}/delete`);
    }catch (e) {
        console.log(e);
        throw e;
    }
}

const baseUrl = '/user'