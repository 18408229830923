import React from 'react';
import MyNavbar from "./components/MyNavbar";
import {Routes, Route} from "react-router-dom";
import PageHome from "./pages/home/PageHome";
import PageChallenge from "./pages/challenge/PageChallenge";
import PagePrivacy from "./pages/privacy/PagePrivacy";
import NotFound from "./pages/not_found/NotFound";
import BottomAppCanvas from "./components/BottomAppCanvas";
import PageTerms from "./pages/terms/PageTerms";
import {routeName} from "./constants/AppConstants";
import PageMobileApp from "./pages/mobile-app/PageMobileApp";
import PageAboutUs from "./pages/about_us/PageAboutUs";
import PageCookiePolicy from "./pages/cookie_policy/PageCookiePolicy";
import PageReport from "./pages/admin/report/PageReport";
import PageReview from "./pages/admin/review/PageReview";
import PageTrack from "./pages/admin/track/PageTrack";
import PageAdmin from "./pages/admin/PageAdmin";
import AdminNavigation from "./components/AdminNavigation";
import PageTag from "./pages/admin/tag/PageTag";
import PageUserDeletion from "./pages/user_deletion/PageUserDeletion";
import AccountDeletion from "./pages/account_deletion/AccountDeletion";


function App() {

    return (
        <div className="App d-flex flex-column">
            <MyNavbar/>
            <Routes>
                <Route path={routeName.home} element={<PageHome/>}/>
                <Route path={routeName.accountDeletion} element={<AccountDeletion/>}/>
                <Route path={routeName.admin} element={<PageAdmin/>}>
                    <Route path={''} element={<AdminNavigation/>}/>
                    <Route path={routeName.report + '/:reportId'} element={<PageReport/>}/>
                    <Route path={routeName.review} element={<PageReview/>}/>
                    <Route path={routeName.track} element={<PageTrack/>}/>
                    <Route path={routeName.tag} element={<PageTag/>}/>
                </Route>
                <Route path={routeName.mobileApp} element={<PageMobileApp/>}/>
                <Route path={routeName.challenge} element={<PageChallenge/>}/>
                <Route path={routeName.aboutUs} element={<PageAboutUs/>}/>
                <Route path={routeName.privacy} element={<PagePrivacy/>}/>
                <Route path={routeName.cookiePolicy} element={<PageCookiePolicy/>}/>
                <Route path={routeName.terms} element={<PageTerms />}/>
                <Route path={routeName.deleteUser} element={<PageUserDeletion/>}/>
                <Route path={routeName.notFound} element={<NotFound/>}/>
            </Routes>
            <BottomAppCanvas/>
        </div>
    );
}

export default App;