import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap";
import {auth} from "../FirebaseSetup";

LogoutButton.propTypes = {

};

interface Props {
    callback?: Function | undefined
}

function LogoutButton(props:React.PropsWithChildren<Props>) {
    const {callback} = props;
    return (
        <Button variant="primary" type="button" onClick={() => {
            auth.signOut().then(()=>{
                if(callback){
                    callback();
                }
            });
        }}>
            Logout
        </Button>
    );
}

export default LogoutButton;