import React from 'react';
import {Toast, ToastContainer} from "react-bootstrap";
import {AdminToastParam, Status} from "../interface/AdminToastParam";

AdminToast.propTypes = {

};

interface Props {
    toasts : AdminToastParam[],
    setToasts : React.Dispatch<React.SetStateAction<AdminToastParam[]>>
 }

function AdminToast(props: React.PropsWithChildren<Props>) {
    const {toasts , setToasts} = props;

    function getStatus(toast : AdminToastParam) {
        switch (toast?.status) {
            case Status.enabled:
                return 'Enabled';
            case Status.disabled:
                return  'Disabled';
            case Status.approved:
                return  'Approved';
        }
    }

    function getToast(timestamp: number) : AdminToastParam {
        return toasts[getIndex(timestamp)];
    }

    function getIndex(timestamp: number): number{
        return  toasts.findIndex(toast => toast.timestamp === timestamp);
    }

    return (
        <div
            aria-live="polite"
            aria-atomic="true"
            className="position-relative"
        >

        <ToastContainer position="top-center" className="p-3">
            {toasts.map(toast => (
                <Toast key={toast.timestamp}
                    onClose={() => {
                        setToasts(prevState => prevState.filter(obj => obj.timestamp !== toast.timestamp ))
                    }}
                    show={getIndex(toast.timestamp) !== -1} delay={2000} autohide>
                    <Toast.Header>
                        <strong className="me-auto">{getStatus(getToast(toast.timestamp))} {toast?.type}</strong>
                    </Toast.Header>
                    <Toast.Body> The {toast?.type} is now {getStatus(getToast(toast.timestamp))}!</Toast.Body>
                </Toast>
            ))
            }
        </ToastContainer>

        </div>
    );
}

export default AdminToast;