import {lang} from "../interface/Lang";

 //export const API_BASE_URL = 'http://localhost:5000';
 export const API_BASE_URL = 'https://gatschu-back.herokuapp.com';


export const routeName = {
    home: '/',
    admin: 'admin',
    report: 'report',
    review: 'review',
    track: 'track',
    tag: 'tag',
    challenge: 'challenge/:id',
    mobileApp: 'mobile-app',
    privacy: 'privacy',
    terms: 'terms',
    cookiePolicy: 'cookie-policy',
    aboutUs: 'about-us',
    deleteUser: 'delete-user',
    accountDeletion: 'account-deletion',
    notFound: '*'
}


export enum LoadingState {
    init,
    loading,
    error,
    successful
}

export enum ReportSubject {
    spam,
    porn,
    harmful,
    illegal,
    other
}

export enum ItemType { none, user, post, ad, comment, tag, notification }


export const lngCode = {
    sv: 'sv',
    en: 'en'
};

export const I18nLng = new Map<string, lang>([
    [lngCode.sv, {nativeName: 'Svenska'}],
    [lngCode.en, {nativeName: 'English'}]
]);

export const ImageSize = {
    lg: '512x512',
    md: '256x256',
    sm: '128x128'
}