import React, {useEffect, useState} from 'react';
import {Offcanvas} from "react-bootstrap";
import '../styles/get_app_canvas.scss'
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import AppButtons from "./AppButtons";
import {routeName} from "../constants/AppConstants";

function BottomAppCanvas() {
    const {t} = useTranslation();
    const [show, setShow] = useState(false);

    const location = useLocation();

    useEffect(() => {
        if (!location.pathname.includes(routeName.admin) && !location.pathname.includes(routeName.terms)  && !location.pathname.includes(routeName.privacy)  ) {
            setShow(true);
        }
    }, [])


    const handleClose = () => setShow(false);


    return (
        <div>
            <Offcanvas show={show} onHide={handleClose} placement="bottom">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('get_app_canvas.title')}</Offcanvas.Title>

                </Offcanvas.Header>
                <Offcanvas.Body className="px-3 py-0 text-center">
                    <div className="d-flex flex-column">
                        <p className="mb-2">{t('get_app_canvas.sub-title')}</p>
                        <AppButtons/>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default BottomAppCanvas;