import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Col, Container, Row, Card, Spinner} from "react-bootstrap";
import {AuthContext} from "../../context/AuthContext";
import {auth} from "../../FirebaseSetup";
import {Post} from "../../interface/Post";
import {getPost} from "../../repository/PostRepository";
import AvatarWithName from "../../components/AvatarWithName";
import TextBody from "../../components/TextBody";
import Tags from "../../components/Tags";
import LikeIcon from "../../components/LikeIcon";
import ViewsIcon from "../../components/ViewsIcon";
import ErrorMsg from "../../components/ErrorMsg";
import {useTranslation} from "react-i18next";
import MyVideoPlayer from "../../components/MyVideoPlayer";
import "../../styles/page_challenge.scss"
import Challenge from "../../components/Challenge";

PageChallenge.propTypes = {};

function PageChallenge() {
    const [error, setError] = useState<boolean>(false);
    const [post, setPost] = useState<Post | null>(null);
    const {user, initialized} = useContext(AuthContext);
    let params = useParams();
    const {t} = useTranslation();

    useEffect(() => {
        async function loginAnonymous() {
            await auth.signInAnonymously();
        }

        async function fetchPost(id: string): Promise<Post> {
            return await getPost(id);
        }

        if (!user && initialized) {
            loginAnonymous().catch((error => {
                    console.log(error);
                    setError(true);
                }
            ));

        } else if (user && initialized && params.id) {
            fetchPost(params.id).then(r => setPost(r)).catch((error) => {
                console.log(error);
                setError(true)
            });
        }
    }, [user, initialized, params.id])

    if (!post) {
        return (
            <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
                {
                    !error ? <Spinner animation="border"/> : <ErrorMsg msg={t('page.challenge.error_message')}/>
                }

            </div>)
    }

    return (
        <Container className="d-flex flex-column justify-content-center vh-100">
            <Row>
                <Col>
                 <Challenge post={post}/>
                </Col>
            </Row>
        </Container>
    );
}

export default PageChallenge;