import React, {useEffect, useState} from 'react';
import {Post} from "../../../interface/Post";
import {MyComment} from "../../../interface/MyComment";
import {toggleDisableUser} from "../../../repository/UserRepository";
import {toggleDisableComment} from "../../../repository/CommentRepository";
import {Container} from "react-bootstrap";
import Login from "../../../components/Login";
import {ItemType} from "../../../constants/AppConstants";
import ReportScreen from "../../../components/ReportScreen";
import {useParams} from "react-router";
import {toggleDisablePost} from "../../../repository/PostRepository";
import {Report} from "../../../interface/Report";
import {getReport} from "../../../repository/ReportRepository";
import {useSignedInAsAdmin} from "../../../hooks/useSignedInAsAdmin";
import CenterSpinnerCover from "../../../components/CenterSpinnerCover";
import {AdminToastParam, Status} from "../../../interface/AdminToastParam";
import AdminToast from "../../../components/AdminToast";
import {createToast} from "../../../utils/funtions";


function PageReport() {
    const [report, setReport] = useState<Report | any>(null)

    const [isLoading, setIsLoading] = useState(false);
    const [toasts, setToasts] = useState<AdminToastParam[]>([])
    const signedInAsAdmin = useSignedInAsAdmin();

    let {reportId} = useParams();


    useEffect(() => {
        let isApiSubscribed = true;

        function fetchReport(id: string) {
            getReport(id).then(value => {
                    if (isApiSubscribed) {
                        setReport(value);
                    }

                }
            );
        }

        if (signedInAsAdmin && reportId) {
            fetchReport(reportId);
        }

        return () => {
            // cancel the subscription
            isApiSubscribed = false;
        };
    }, [reportId, signedInAsAdmin])


    function handleClick(id: string, itemType: ItemType) {
        setIsLoading(true);
        if (itemType === ItemType.user) {
            toggleDisableUser(id).then((value => {
                let status = value ? Status.disabled : Status.enabled;
                let toast: AdminToastParam =  createToast( 'user',status );
                setToasts(prevState => [...prevState, toast])
                setReport((prevState: Report) => {
                    if ("user" in prevState.regarding) {
                        return {
                            ...prevState,
                            regarding: {
                                ...prevState.regarding,
                                user: {
                                    ...prevState.regarding.user, disabled: value
                                }
                            }
                        };
                    } else {
                        return {
                            ...prevState,
                            regarding: {
                                user: {
                                    ...prevState.regarding, disabled: value
                                }
                            }
                        };
                    }


                })
            })).finally(() => setIsLoading(false));
        } else if (itemType === ItemType.post) {
            toggleDisablePost(id).then((value => {
                let status = value ? Status.disabled : Status.enabled;
                let toast: AdminToastParam =  createToast( 'challenge',status );
                setToasts(prevState => [...prevState, toast])
                setReport((prevState: Report) => {
                    return {
                        ...prevState,
                        regarding: {...prevState.regarding, disabled: value} as Post
                    };
                })
            })).finally(() => setIsLoading(false));
        } else if (itemType === ItemType.comment) {
            toggleDisableComment(id).then((value => {
                let status = value ? Status.disabled : Status.enabled;
                let toast: AdminToastParam =  createToast( 'comment',status );
                setToasts(prevState => [...prevState, toast])
                setReport((prevState: Report) => {
                    return {
                        ...prevState,
                        regarding: {...prevState.regarding, disabled: value} as MyComment
                    }
                })
            })).finally(() => setIsLoading(false));
        }
    }

    return (
        <Container className="h-100 d-flex flex-column">
            {!signedInAsAdmin && <Login/>}
            {report != null &&
            <ReportScreen setReport={setReport} handleClick={handleClick} report={report}/>
            }
            {isLoading && <CenterSpinnerCover/>
            }
            <AdminToast setToasts={setToasts} toasts={toasts}/>
        </Container>
    );
}

export default PageReport;