import React from 'react';
import PropTypes from 'prop-types';
import {Col, Container, Image, Row} from "react-bootstrap";
import {appImage, deleteImg} from "../../constants/ImgAssets";
import AppButtons from "../../components/AppButtons";
import {Link} from "react-router-dom";
import {routeName} from "../../constants/AppConstants";

PageUserDeletion.propTypes = {};

function PageUserDeletion() {
    return (
        <Container className="d-flex flex-column justify-content-lg-center h-100 mt-5">
            <Row>
                <Col>
                    <h2>How to delete user data</h2>
                </Col>
            </Row>
            <Row className="justify-content-center">
                <Image className="" src={deleteImg} style={{objectFit: "contain", height: '500px'}}/>
            </Row>
            <Row className={"text-start"}>
                <Col>
                    <div className='d-flex flex-column'>
                        <p>
                            To delete your user data go <Link to={'../' + routeName.accountDeletion}>here</Link>, or by
                            the app
                            follow the following steps.
                            If you have any problem, contact us at <a
                            href="mailto:info@gatschu.com">info@gatschu.com</a>, and we can help you with the deletion.
                        </p>
                        <ol>
                            <li>
                                <p>Click on the Profile icon and make sure you are logged in.</p>
                            </li>
                            <li>
                                <p>Click on the cog-icon, and a drawer will open up from the right.</p>
                            </li>
                            <li>
                                <p>Click on "Delete-account" text button.</p>
                            </li>
                            <li>
                                <p>On the dialog that pops up click on "Delete". This will directly delete all personal
                                    data we have on you.</p>
                            </li>
                        </ol>

                    </div>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <AppButtons/>
                </Col>
            </Row>
        </Container>
    );
}

export default PageUserDeletion;