export enum Status {
    enabled,
    disabled,
    approved
}

export interface AdminToastParam {
    status: Status,
    type: string,
    timestamp: number
}
