import React from 'react';
import {MyTrack} from "../interface/MyTrack";
import {Image, Card, Badge} from "react-bootstrap";
import {urlToResizedImageUrl} from "../utils/funtions";


interface Props {
    track: MyTrack
    onEdit: Function
    isSelected: boolean
}

function ListItemTrack(props: Props) {
    const {track, onEdit, isSelected} = props;
    const size = '100px';


    return (
        <Card border={isSelected ? 'primary' : 'light'} style={{height: "auto", overflow: "clip"}}
              className="d-flex flex-row">
            <Image style={{objectFit: "cover", height: size, width: size, borderRadius: "7px"}}
                   src={urlToResizedImageUrl(track.imgUrl)}/>

            <Card.Body className="d-flex flex-row">
                <div className="d-flex flex-column flex-grow-1 text-start">
                    <b>{track.title}</b>
                    <span>{track.artist}</span>
                    <div>
                        {track.genre.map(e=>  <Badge pill key={e} className={"me-1"} bg="primary">
                            {e.trim()}
                        </Badge>)}
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}

export default ListItemTrack;