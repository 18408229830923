import appImg0 from '../assets/img_app/android_0.png'
import appImg1 from '../assets/img_app/android_1.png'
import appImg2 from '../assets/img_app/android_2.png'
import appImg3 from '../assets/img_app/android_3.png'
import appImg4 from '../assets/img_app/android_4.png'
import deletionImg from '../assets/img_app/deletion_steps.png'


export const appImage = [
    appImg0,
    appImg1,
    appImg2,
    appImg3,
    appImg4];

export const deleteImg = deletionImg;