import {useContext, useEffect, useState} from "react";
import {auth} from "../FirebaseSetup";
import {AuthContext} from "../context/AuthContext";

export function useSignedInAsAdmin (){
    const [signedInAsAdmin, setSignedInAsAdmin] = useState(false);
    const {user, initialized} = useContext(AuthContext);


    useEffect(() => {
        // set our variable to true
        let isApiSubscribed = true;
        async function signOutAnon() {
            await auth.signOut();
        }

        async function isAdmin(): Promise<boolean> {
            return user?.getIdTokenResult().then((idTokenResult) => {
                // Confirm the user is an PageAdmin.
                return !!idTokenResult.claims.admin;
            }) ?? false;
        }

        if (user) {
            isAdmin().then(value => {
                if(isApiSubscribed){
                    if (value) {
                        setSignedInAsAdmin(true);
                    } else {
                        signOutAnon().then(() =>
                            setSignedInAsAdmin(false)
                        )
                    }
                }
            });
        } else if (!user && initialized) {
            setSignedInAsAdmin(false);
        }
        return () => {
            // cancel the subscription
            isApiSubscribed = false;
        };
    }, [user, initialized]);

    return signedInAsAdmin;

}