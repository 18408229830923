import React from 'react';
import {isAndroid, isIOS, isMobile} from "react-device-detect";
import ImageLink from "./ImageLink";
import imgGooglePlay from "../assets/google_play.png";
import imgAppStore from "../assets/app_store.png";

AppButtons.propTypes = {

};

function AppButtons() {

    const isAndroidPhone = () => isAndroid && isMobile;
    const isIPhone = () => isIOS && isMobile;
    const isOther = () => !isAndroidPhone() && !isIPhone();

    const googlePlayLink = () => <ImageLink height="3.5em" url='https://play.google.com/store/apps/details?id=se.infinitee.gatschu'
                                            imageSrc={imgGooglePlay}> </ImageLink>
    const appleAppStoreLink = () => <ImageLink height="3.5em" url='https://apps.apple.com/app/gatschu/id1589840021'
                                               imageSrc={imgAppStore}> </ImageLink>

    return (
        <div className="d-flex justify-content-evenly">
            {(isIPhone() || isOther()) && appleAppStoreLink()}
            {(isAndroidPhone() || isOther()) && googlePlayLink()}
        </div>
    );
}

export default AppButtons;