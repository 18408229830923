import React from 'react';
import {Container} from "react-bootstrap";
import {useTranslation} from "react-i18next";


PageHome.propTypes = {};

function PageHome() {
    const {t} = useTranslation();
    return (
        <Container fluid={true} className="p-0 m-0 h-100 vw-100 overflow-hidden position-relative">
            <video width="100%" height="100%" style={{objectFit: "cover"}} loop playsInline autoPlay muted preload="auto">
                <source
                    src={require('../../assets/video/bg_video.mp4')}
                    type="video/mp4"
                />
                Your browser does not support the video tag.
            </video>
            <div
                className="position-absolute  bottom-50 start-50 translate-middle-x justify-content-center d-flex flex-column  text-white">
                <h1>{t('page.home.title')}</h1>
                <h3>
                    {t('page.home.body')}
                </h3>
            </div>


        </Container>
    );

}

export default PageHome;