import React, {useContext, useEffect} from 'react';
import {useSignedInAsAdmin} from "../../hooks/useSignedInAsAdmin";
import Login from "../../components/Login";
import {Outlet, useLocation, useNavigate} from "react-router-dom"
import {Button, Col, Row} from "react-bootstrap";
import LogoutButton from "../../components/LogoutButton";
import {routeName} from "../../constants/AppConstants";
import {AuthContext} from "../../context/AuthContext";


function PageAdmin() {

    const signedInAsAdmin = useSignedInAsAdmin();
    const {initialized} = useContext(AuthContext);
    const location = useLocation();
    let navigate = useNavigate();

    function isInSubPath() {
        return location.pathname.includes(routeName.track)
            || location.pathname.includes(routeName.report)
            || location.pathname.includes(routeName.review)
            || location.pathname.includes(routeName.tag);
    }

    useEffect(() => {

        if (!signedInAsAdmin && isInSubPath() && initialized) {
            goBack();
        }

    }, [signedInAsAdmin, location]);

    function goBack() {
        navigate("./");
    }

    if (!signedInAsAdmin) {
        return (<Login/>);
    }




    return (
        <div className='d-flex flex-column'>
            <Outlet/>
            {signedInAsAdmin && <Row>
                <Col className='d-flex justify-content-evenly mt-5'>
                    <LogoutButton />
                    {isInSubPath() && <Button onClick={() => goBack()}>Back to Menu</Button>}
                </Col>
            </Row>}
        </div>
    );
}

export default PageAdmin;