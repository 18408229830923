import React from 'react';
import {Spinner} from "react-bootstrap";

CenterSpinnerCover.propTypes = {

};

function CenterSpinnerCover() {
    return (
        <div
            className="bg-black bg-opacity-25 d-flex justify-content-center align-items-center position-absolute w-100 h-100 "
            style={{top: "0", left: "0"}}><Spinner
            variant="primary"
            animation={"border"}/>
        </div>
    );
}

export default CenterSpinnerCover;