import React from 'react';
import PropTypes from 'prop-types';

TextBody.propTypes = {
    text : PropTypes.string
};

interface Props {
    text : string
}

function TextBody(props :React.PropsWithChildren<Props>) {
    return (
        <div className="p-1">
            <p style={{textAlign: "start"}}>
                {props.text}
            </p>
        </div>
    );
}

export default TextBody;