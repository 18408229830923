import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDMxlFePBOrrXudH3dfNlkFhXnee47x9yk",
    authDomain: "iblof-6ff10.firebaseapp.com",
    databaseURL: "https://iblof-6ff10.firebaseio.com",
    projectId: "iblof-6ff10",
    storageBucket: "iblof-6ff10.appspot.com",
    messagingSenderId: "845076908037",
    appId: "1:845076908037:web:9d08f245c2546987a8346c"
};

firebase.initializeApp(firebaseConfig);

export const  auth = firebase.auth();
export const  storage = firebase.storage();
