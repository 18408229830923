import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Badge, Button, Card, Col, Row} from "react-bootstrap";
import MyVideoPlayer from "./MyVideoPlayer";
import {ItemType, ReportSubject} from "../constants/AppConstants";
import AvatarWithName from "./AvatarWithName";
import {MyUser} from "../interface/MyUser";
import DisableAllModal from "./DisableAllModal";
import {Report} from "../interface/Report";
import DisableButton from "./DisableButton";
import LogoutButton from "./LogoutButton";


ReportScreen.propTypes = {
    report: PropTypes.object,
    setReport: PropTypes.func,
    handleClick: PropTypes.func
};

interface Props {
    report: Report,
    setReport: React.Dispatch<React.SetStateAction<Report | unknown>>,
    handleClick: Function
}

export interface ModalParams {
    type: ItemType,
    user: MyUser
}


function ReportScreen(props: React.PropsWithChildren<Props>) {
    const {report, handleClick, setReport} = props;
    const {reporter, regarding} = report;
    const [modalParams, setModalParams] = useState<ModalParams | null>()

    const getUser = (): MyUser => {
        if ("user" in regarding) {
            return regarding.user as MyUser;
        } else {
            return regarding as MyUser;
        }
    };


    function callback() {
        if (report.type === modalParams?.type) {
            setReport((prevReport: Report) => {
                return {
                    ...prevReport,
                    regarding: {...prevReport.regarding, disabled: true}
                }
            });
        }
    }


    return (
        <div className="d-flex flex-column text-start">
            <Row>
                <Col lg={3} className="d-flex flex-column">
                    <Card>
                        <Card.Img variant={"top"} src={reporter?.profileImgUrl}>
                        </Card.Img>
                        <Card.Body>
                            <Card.Title>
                                Reporter:
                            </Card.Title>
                            <Card.Subtitle className="mb-1 text-muted">{reporter?.displayName}</Card.Subtitle>
                            <Card.Text className="mb-1 text-muted">{'@' + reporter?.username}</Card.Text>
                            <Card.Link href={"mailto:" + reporter?.email}>{reporter?.email}</Card.Link>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Reason: {ReportSubject[report.subject]}
                            </Card.Title>
                            <Card.Text>
                                {report.description}
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card body className="d-flex flex-column">
                        <Card.Title className="p-1 d-flex flex-column">
                            <div>
                                Report: #{report.number}
                            </div>
                            <div>
                                {report.type === ItemType.post ? 'challenge' : report.type === ItemType.comment ? 'comment' : 'user'}:
                            </div>
                        </Card.Title>
                        {report.type !== ItemType.user &&
                        <Card className="my-1">
                            {"videoUrl" in regarding && <MyVideoPlayer url={regarding.videoUrl}/>}
                            {"tags" in regarding &&
                            <div>
                                {
                                    regarding.tags?.map((tag: string) => <Badge key={tag} pill text={"dark"}
                                                                                bg={"primary"}>{'#' + tag} </Badge>)
                                }
                            </div>
                            }
                            <Card.Body>
                                <Card.Text className="mb-1">{"text" in regarding ? regarding?.text : ''}</Card.Text>
                                <div className="d-flex justify-content-center">
                                    <DisableButton onClick={() => handleClick(regarding?.id, report.type)} regarding={regarding} type={report.type}/>
                                </div>
                            </Card.Body>

                        </Card>
                        }
                        <Card body className="d-flex my-1">
                            <Card.Title className="p-1">
                                {report.type !== ItemType.user && 'Creator:'}
                            </Card.Title>
                            <div className="d-flex flex-column">
                                <AvatarWithName user={getUser()}/>
                                <Card.Link
                                    href={"mailto:" + getUser().email}>{getUser().email}</Card.Link>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button variant={getUser().disabled ? "success" : "danger"}
                                        onClick={() => handleClick(getUser().id, ItemType.user)}>
                                    {getUser().disabled ? "Enable" : "Disable"} user
                                </Button>
                            </div>
                        </Card>
                        <Card body className=" my-1">
                            <Card.Title className="p-1">Disable all of
                            </Card.Title>
                            <Card.Text>{getUser().displayName} (@{getUser().username})
                            </Card.Text>
                            <div className="d-flex justify-content-around">
                                <div><Button variant={"warning"}
                                             onClick={() => setModalParams({user: getUser(), type: ItemType.post})}>
                                    challenges
                                </Button></div>
                                <div>
                                    <Button variant={"warning"} onClick={() => setModalParams({
                                        user: getUser(),
                                        type: ItemType.comment
                                    })}>comments</Button>
                                </div>
                            </div>
                        </Card>
                    </Card>
                </Col>
            </Row>


            <DisableAllModal show={modalParams != null} onHide={() => setModalParams(null)} callback={callback}
                                                     params={modalParams}/>

        </div>
    );
}

export default ReportScreen;