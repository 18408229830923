import React, {useState} from 'react';
import {Container, Nav, NavDropdown, Navbar} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import logo from '../assets/logo_512.png';
import LanguageDropDown from "./LanguageDropDown";
import {routeName} from "../constants/AppConstants";


MyNavbar.propTypes = {};

function MyNavbar() {
    const {t} = useTranslation();

    const [expanded, setExpanded] = useState(false);

    return (
        <Navbar expanded={expanded} bg="light" expand="lg" sticky="top">
            <Container>
                <Navbar.Brand onClick={() => setExpanded(false)} className={"d-flex"} as={NavLink} to={routeName.home}>
                    <img
                        alt="logo"
                        src={logo}
                        width="50"
                        height="50"
                        className="d-inline-block align-top"
                    />
                    <div className={"d-flex flex-column justify-content-center"}>
                       <span style={{fontSize: "1.5em", color: "#FF9800"}}>
                        atschu
                       </span>
                    </div>
                </Navbar.Brand>
                <Navbar.Toggle onClick={() => setExpanded(!expanded)} aria-controls="basic-navbar-nav"/>
                <Navbar.Collapse className="justify-content-end text-start" id="basic-navbar-nav">
                    <Nav>
                        <Nav.Link as={NavLink} onClick={() => setExpanded(false)}
                                  to={routeName.home}>{t('nav_bar.links.home')}</Nav.Link>
                        <Nav.Link as={NavLink} onClick={() => setExpanded(false)}
                                  to={routeName.mobileApp}>{t('nav_bar.links.mobile_app')}</Nav.Link>
                        <Nav.Link as={NavLink} onClick={() => setExpanded(false)}
                                  to={routeName.aboutUs}>{t('nav_bar.links.about_us')}</Nav.Link>
                        <NavDropdown title={t('nav_bar.links.legal.title')}>
                            <NavDropdown.Item as={NavLink}
                                              onClick={() => setExpanded(false)}
                                              to={routeName.privacy}>{t('nav_bar.links.legal.privacy')}</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink}
                                              onClick={() => setExpanded(false)}
                                              to={routeName.terms}>{t('nav_bar.links.legal.terms')}</NavDropdown.Item>
                            <NavDropdown.Item as={NavLink}
                                              onClick={() => setExpanded(false)}
                                              to={routeName.cookiePolicy}>{t('nav_bar.links.legal.cookies')}</NavDropdown.Item>
                        </NavDropdown>
                        <LanguageDropDown cb={() => setExpanded(false)}/>
                    </Nav>
                </Navbar.Collapse>

            </Container>
        </Navbar>
    );
}

export default MyNavbar;