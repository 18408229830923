import React from 'react';
import PropTypes from 'prop-types';

Tags.propTypes = {
    tags: PropTypes.array
};

interface Props {
    tags: Array<string>
}

function Tags(props: React.PropsWithChildren<Props>) {
    return (
        <div className=" p-1 d-flex">
            <b>
                {props.tags.map(tag => '#' + tag).join(' ')}
            </b>
        </div>
    );
}

export default Tags;