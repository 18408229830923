import React from 'react';
import { Modal } from 'react-bootstrap';
import {ModalProps} from "react-bootstrap/Modal";
import CenterSpinnerCover from "./CenterSpinnerCover";



function LoadingModal(props : ModalProps) {
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Body style={{height: "200px"}}>
                <CenterSpinnerCover/>
            </Modal.Body>
        </Modal>
    );
}

export default LoadingModal;