import React from 'react';
import PropTypes from 'prop-types';
import {MyUser} from "../interface/MyUser";
import { Image} from "react-bootstrap";
import {urlToResizedImageUrl} from "../utils/funtions";

AvatarWithName.propTypes = {
    user : PropTypes.object
};

interface Props{
    user : MyUser
}

function AvatarWithName(props:React.PropsWithChildren<Props>) {
   const { user } = props;

    return (
        <div className="d-flex p-1">
            <div>
                <Image style={{objectFit: "cover", width: "3em", height : "3em"}}  roundedCircle={true}
                       src={urlToResizedImageUrl(user.profileImgUrl)}/>
            </div>
            <div className="mx-1 d-flex flex-column align-items-start" >
                <h5>{user.displayName}</h5>
                <h6>@{user.username}</h6>
            </div>
        </div>
    );
}

export default AvatarWithName;