import {getData} from "./client";
import {Report} from "../interface/Report";

export async function getReport(id: string) : Promise<Report>{
    try{
        return await getData(`/report/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}