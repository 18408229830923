import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import MyVideoPlayer from "./MyVideoPlayer";
import AvatarWithName from "./AvatarWithName";
import LikeIcon from "./LikeIcon";
import ViewsIcon from "./ViewsIcon";
import TextBody from "./TextBody";
import Tags from "./Tags";
import {Post} from "../interface/Post";

Challenge.propTypes = {

};

interface Props {
    post: Post
}

function Challenge(props: React.PropsWithChildren<Props>) {
    const{post} = props;

    return (
        <Card className="d-flex flex-column w-100" >
            <Row>
                <Col>
                    <div className="video-box">
                        <MyVideoPlayer url={post.videoUrl}/>
                    </div>
                </Col>
            </Row>
            <div className="d-flex flex-column">
                <Row className="justify-content-between">
                    <Col xs={8} style={{overflow: "hidden"}}>
                        <AvatarWithName user={post.user}/>

                    </Col>
                    <Col>
                        <div className="d-flex me-1 justify-content-end" style={{height: "2em"}}>
                            <LikeIcon likes={post.likes}/>
                            <div className="mx-1"/>
                            <ViewsIcon views={post.views}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextBody text={post.text}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Tags tags={post.tags}/>
                    </Col>
                </Row>
            </div>
        </Card>
    );
}

export default Challenge;