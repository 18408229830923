import React, {useEffect, useState} from 'react';
import {useSignedInAsAdmin} from "../../../hooks/useSignedInAsAdmin";
import {Button, Card, Col, Container, Row, Spinner} from "react-bootstrap";
import {MyTrack} from "../../../interface/MyTrack";
import {default as _ReactPlayer} from "react-player";
import {ReactPlayerProps} from "react-player/types/lib";
import InfiniteScroll from "react-infinite-scroll-component";
import {getTracks} from "../../../repository/TrackRepository";
import AddTrackModal from "../../../components/AddTrackModal";
import ListItemTrack from "../../../components/ListItemTrack";
import {getErrorMessage} from "../../../utils/error_handling";
import LoadingModal from "../../../components/LoadingModal";

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

function PageTrack() {
    const signedInAsAdmin = useSignedInAsAdmin();
    const [tracks, setTracks] = useState<MyTrack[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [selectedTrack, setSelectedTrack] = useState<MyTrack | null>(null);
    const [endReached, setEndReached] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false)


    useEffect(() => {


        function fetchTracks() {
            handleFetch().then();
        }

        if (signedInAsAdmin) {
            fetchTracks();
        }


    }, [signedInAsAdmin])


    async function handleFetch() {
        try {
            const limit = 50;
            let result = await getTracks(limit, tracks.length);
            setTracks(oldState => Array.from([...oldState, ...result]
                .reduce((m, o) => m.set(o.id, o), new Map)
                .values()));
            setEndReached(limit != result.length);
        } catch (e: any) {
            const errorMsg = getErrorMessage(e);
            setError(errorMsg)
        }
    }


    //NOT NEEDED ANYMORE
    // function onLogout() {
    //     setTracks([]);
    //     setEndReached(false);
    //     setSelectedTrack(null);
    //     setError(null);
    // }

    return (
        <Container
            style={{overflow: "hidden" }} className={tracks.length === 0 ? "h-100 d-flex flex-column justify-content-center" : "h-100 d-flex flex-column"}>

            <Row className="my-5">
                <Col className='d-flex justify-content-evenly'>
                    <Button
                        onClick={() => setShowModal(true)}>{selectedTrack == null ? 'Add Track' : 'Edit Track'}</Button>
                    {selectedTrack != null && <Button onClick={() => setSelectedTrack(null)}>Unselect</Button>}
                </Col>
            </Row>
            <Row>
                <Col className="d-flex justify-content-center">
                    {
                        selectedTrack && selectedTrack.url && <ReactPlayer height={'40px'}
                                                                           controls={true}
                                                                           url={selectedTrack?.url}
                                                                           config={{
                                                                               file: {
                                                                                   forceAudio: true
                                                                               }
                                                                           }}/>
                    }

                </Col>
            </Row>

            <div className="mb-5" style={{maxHeight: "500px", overflow:"auto"}}>
                <InfiniteScroll
                    dataLength={tracks.length}
                    next={handleFetch}
                    hasMore={!endReached}
                    loader={
                        <LoadingModal  show={true}/>
                    }
                    endMessage={
                        <b style={{textAlign: 'center'}}>
                            <b>Nothing more to load</b>
                        </b>
                    }
                >
                    {
                        tracks.map(e =>
                            <div key={e.id} onClick={() => {
                                setSelectedTrack(selectedTrack != null && selectedTrack.id === e.id ? null : e);
                            }}>
                                <ListItemTrack track={e} isSelected={selectedTrack != null && selectedTrack.id === e.id}
                                               onEdit={() => {
                                               }}/>
                            </div>
                        )
                    }

                </InfiniteScroll>

            </div>
            {
                showModal && <AddTrackModal track={selectedTrack} setTracks={setTracks} show={true}
                                            onHide={() => setShowModal(false)}/>
            }

        </Container>
    );
}

export default PageTrack;