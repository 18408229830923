import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {useNavigate} from "react-router-dom";
import {routeName} from "../constants/AppConstants";

AdminNavigation.propTypes = {

};

function AdminNavigation() {

    let navigate = useNavigate();


    return (
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
            <Card className='admin-nav'>
               <Card.Body className="d-flex flex-column ">
                   <Button onClick={() => navigate(routeName.review)}>Review</Button>
                   <Button onClick={() => navigate(routeName.track)}>Track</Button>
                   <Button onClick={() => navigate(routeName.tag)}>Tag</Button>
               </Card.Body>
            </Card>
        </div>
    );
}

export default AdminNavigation;