import React, {useEffect, useState} from "react";
import {auth} from "../FirebaseSetup";
import {AuthContext} from "../context/AuthContext";
import firebase from "firebase/compat/app";

function  AuthProvider(props: React.PropsWithChildren<{}>){
    const [user, setUser] = useState<firebase.User | null>( null );
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(()=>{
        const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
           setUser(firebaseUser);
           if (!initialized){
               setInitialized(true);
           }
        });
        return unsubscribe;

    },[]);

    return <AuthContext.Provider value={{user, initialized}} {...props}/>;

}

export default AuthProvider;