import React from 'react';
import {NavDropdown} from "react-bootstrap";
import {I18nLng, lngCode} from "../constants/AppConstants";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";


LanguageDropDown.prototype = {
    cb: PropTypes.func
}

interface Props {
    cb: Function
}

function LanguageDropDown(props: React.PropsWithChildren<Props>) {
    const {cb} = props;
    const {i18n} = useTranslation();

    const currentLng = () => I18nLng.get(i18n.language)?.nativeName ?? I18nLng.get(lngCode.en)?.nativeName;


    return (
        <NavDropdown title={currentLng()}>
            {
                Array.from(I18nLng.keys()).map(e =>
                    <NavDropdown.Item key={e}
                                      onClick={() => {
                                          cb();
                                          return i18n.changeLanguage(e);
                                      }}>{I18nLng.get(e)?.nativeName}</NavDropdown.Item>
                )}
        </NavDropdown>
    );
}

export default LanguageDropDown;