import {getData, putData} from "./client";
import {Post} from "../interface/Post";
import {PostReviewResult} from "../interface/PostReviewResult";

export async function getPost(id: string) : Promise<Post>{
 try{
  return await getData(`/post/${id}`);
 }catch(e){
  console.log(e);
  throw e;
 }
}

export async function getPostToReview() : Promise<PostReviewResult>{
 try{
  return await getData(`/post/review`);
 }catch(e){
  console.log(e);
  throw e;
 }
}

export async function approvePost(id: string) : Promise<boolean>{
 try{
  return await putData(`/post/approve/${id}`);
 }catch(e){
  console.log(e);
  throw e;
 }
}

export async function toggleDisablePost(id: string) : Promise<boolean>{
 try{
  return await putData(`/post/disable-toggle/${id}`);
 }catch(e){
  console.log(e);
  throw e;
 }
}

export async function disableAllPosts(userId: string) : Promise<boolean>{
 try{
  return await putData(`/post/disable-all/${userId}`);
 }catch(e){
  console.log(e);
  throw e;
 }
}