import React from 'react';
import {Alert, Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";



NotFound.propTypes = {

};

function NotFound() {
    const {t} = useTranslation();


    return (
        <Container className="vh-100 d-flex flex-column justify-content-center">
            <Row>
              <Col>
                  <Alert variant="warning">
                      <Alert.Heading>{t('page.not_found.title')}</Alert.Heading>
                      <p>
                         {window.location.href}
                      </p>
                  </Alert>
              </Col>
            </Row>
        </Container>

    );
}

export default NotFound;