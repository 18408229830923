import React from 'react';
import {Col, Container, Image, Row} from "react-bootstrap";
import {appImage} from "../../constants/ImgAssets";
import AppButtons from "../../components/AppButtons";

PageMobileApp.propTypes = {};

function PageMobileApp() {


    return (
        <Container className="d-flex flex-column justify-content-lg-center h-100">
            <Row className="justify-content-center">
                {
                    appImage.map((img, index) => {
                        return <Col className="my-2 my-lg-0" key={index} lg={2}>
                            <Image className="w-100" src={img} style={{objectFit: "contain"}}/>
                        </Col>
                    })

                }
            </Row>
            <Row className="mt-5">
                <Col>
                    <AppButtons/>
                </Col>
            </Row>
        </Container>
    );
}

export default PageMobileApp;