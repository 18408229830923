import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {lngCode} from "./constants/AppConstants";
import common_sv from './translations/sv/common_sv.json';
import common_en from './translations/en/common_en.json';



 i18n
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            debug: true,
            fallbackLng: lngCode.en,
            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default
            },
            resources: {
                [lngCode.en]: {
                    translation: common_en
                },
                [lngCode.sv]: {
                    translation: common_sv
                }
            }
        });

export  default  i18n;