import {getData, putData} from "./client";
import {Post} from "../interface/Post";
import {PostReviewResult} from "../interface/PostReviewResult";

export async function searchTags(search: string, disabled: boolean): Promise<string[]> {
    try {
        return await getData(`/tag/admin-search?${new URLSearchParams({
            search,
            'disabled': String(disabled)
        }).toString()}`,);
    } catch (e) {
        console.log(e);
        throw e;
    }
}


export async function disableTag(tag: string, disable: boolean): Promise<boolean> {
    try {
        return await putData(`/tag/disable?${new URLSearchParams({
            tag,
            'disable': String(disable)
        }).toString()}`,);
    } catch (e) {
        console.log(e);
        throw e;
    }
}
