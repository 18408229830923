import {ImageSize} from "../constants/AppConstants";
import {AdminToastParam, Status} from "../interface/AdminToastParam";
import {storage} from "../FirebaseSetup";


export function urlToResizedImageUrl(url: string | null, size: string = ImageSize.md): string {
    if (!url || url.length < 1) {
        return '';
    }
    let pos = url.lastIndexOf('.');
    return url.substring(0, pos) + `_${size}.` + url.substring(pos + 1);
}

export function shortNumber(n: number): string {
    if (n < 1e3) return n.toString();
    else if (n >= 1e3 && n < 1e6) {
        return +(n / 1e3).toFixed(1) + "K";
    } else {
        return +(n / 1e6).toFixed(1) + "M";
    }
}

export function createToast(type: string, status: Status): AdminToastParam {
    return {type: type, status: status, timestamp: +new Date().getTime()}
}

export async function uploadFile(ref: string, file: File): Promise<string> {
    const storageRef = storage.ref();
    const fileRef = storageRef.child(ref);
    let snapshot = await fileRef.put(file);
    return await snapshot.ref.getDownloadURL();

}