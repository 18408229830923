import React, {useEffect, useState} from 'react';
import {useSignedInAsAdmin} from "../../../hooks/useSignedInAsAdmin";
import {Alert, Button, Card, Col, Container, Row} from "react-bootstrap";
import {approvePost, getPostToReview, toggleDisablePost} from "../../../repository/PostRepository";
import Challenge from "../../../components/Challenge";
import {Post} from "../../../interface/Post";
import CenterSpinnerCover from "../../../components/CenterSpinnerCover";
import {ItemType} from "../../../constants/AppConstants";
import {toggleDisableUser} from "../../../repository/UserRepository";
import {AdminToastParam, Status} from "../../../interface/AdminToastParam";
import AdminToast from "../../../components/AdminToast";
import {MyUser} from "../../../interface/MyUser";
import DisableButton from "../../../components/DisableButton";
import {createToast} from "../../../utils/funtions";


PageReview.propTypes = {};

function PageReview() {
    const signedInAsAdmin = useSignedInAsAdmin();
    const [count, setCount] = useState<number | null>();
    const [index, setIndex] = useState<number>(0);
    const [postsToReview, setPostsToReview] = useState<Post[]>([]);
    const [newReview, setNewReview] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [toasts, setToasts] = useState<AdminToastParam[]>([]);
    const [endReached, setEndReached] = useState<boolean>(false)

    function getPost(): Post {
        return postsToReview[index];
    }

    function getUser(): MyUser {
        return postsToReview[index].user;
    }

    useEffect(() => {
        if (newReview) {
            setNewReview(false);
            setIndex(postsToReview.length - 1);
        }
    }, [newReview])

    useEffect(() => {

        async function fetchPostToReview(): Promise<void> {
            return await handleNextPostToReview();
        }

        if (signedInAsAdmin && postsToReview.length === 0) {
            fetchPostToReview().then();
        }
    }, [signedInAsAdmin, postsToReview])

    async function handleNextPostToReview() {
        setIsLoading(true)
        try {
            let result = await getPostToReview();
            const updatedPost: Post = {
                ...result.post,
                approved: false
            }
            setPostsToReview(prevState => {
                const list = [...prevState, updatedPost];
                const map = new Map(list.map(item => [item.id, item]));
                return Array.from(map.values());
            });
            setCount(result.count);
            setNewReview(true);
        } catch (e) {
            if (e instanceof Error) {
                e.message.includes('404');
                setEndReached(true);
            }

        } finally {
            setIsLoading(false);
        }
    }


    function handleDisableClick(id: string, itemType: ItemType) {
        setIsLoading(true);
        if (itemType === ItemType.user) {
            toggleDisableUser(id).then((value => {
                let status = value ? Status.disabled : Status.enabled;
                let toast: AdminToastParam = createToast('user', status);
                setToasts(prevState => [...prevState, toast]);
                setPostsToReview((prevState: Post[]) => {
                    return prevState.map(post => {
                        const {user} = post;
                        return {
                            ...post,
                            user: {
                                ...post.user,
                                disabled: user.id === id ? value : user.disabled
                            }
                        };

                    });
                })
            })).finally(() => setIsLoading(false));
        } else if (itemType === ItemType.post) {
            toggleDisablePost(id).then((value => {
                let status = value ? Status.disabled : Status.enabled;
                let toast: AdminToastParam = createToast('challenge', status);
                setToasts(prevState => [...prevState, toast])
                setPostsToReview((prevState: Post[]) => {
                    return prevState.map(post => {
                        return {
                            ...post,
                            disabled: post.id === id ? value : post.disabled
                        };

                    });

                })
            })).finally(() => setIsLoading(false));
        }
    }

    async function handleApproveClick(id: string) {
        setIsLoading(true);
        try {
            const approved = await approvePost(id);
            if (approved) {
                setPostsToReview(prevState => {
                    return prevState.map(post => {
                        return {
                            ...post,
                            approved: post.id === id ? approved : post.approved
                        };
                    });
                })
                let toast: AdminToastParam = createToast('challenge', Status.approved);
                setToasts(prevState => [...prevState, toast])
                await handleNextPostToReview();
            }

        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }

    }


    const noMorePosts = () => (
        <Alert variant="success">Great! No Challenges to review! :) </Alert>
    );

    // NOT NEEDED ANYMORE
    // function resetStates() {
    //     setCount(null);
    //     setIndex(0);
    //     setPostsToReview([]);
    //     setEndReached(false);
    // }

    return (
        <div>
            <Container
                className={postsToReview.length === 0 ? "vh-100 d-flex flex-column justify-content-center" : "h-100 d-flex flex-column"}>
                {isLoading && <CenterSpinnerCover/>}
                {postsToReview.length > 0 && <div className="d-flex flex-column">
                    <Challenge post={postsToReview[index]}/>
                    <Card className="mt-1 p-1">
                        <Row>
                            <Col>

                                <h5>Challenges waiting for review: {count}</h5>
                                <h6>Reviewed: {postsToReview.length}, index: {index + 1} </h6>
                            </Col>
                        </Row>
                        {endReached && noMorePosts()}
                        <Row>
                            <Col>

                                {postsToReview.length > 1 && index > 0 &&
                                <Button onClick={() => setIndex(index - 1)}>{"<-"}</Button>

                                }
                            </Col>
                            <Col>
                                <DisableButton onClick={() => handleDisableClick(getUser().id, ItemType.user)}
                                               regarding={getUser()} type={ItemType.user}/>
                            </Col>
                            <Col>
                                <DisableButton onClick={() => handleDisableClick(getPost().id, ItemType.post)}
                                               regarding={getPost()} type={ItemType.post}/>
                            </Col>
                            <Col>
                                {!getPost().disabled && !getUser().disabled &&
                                <Button variant={"success"} disabled={getPost().approved}
                                        onClick={() => handleApproveClick(getPost().id)}>Approve
                                    Post</Button>}

                                {getPost().disabled &&
                                <Button onClick={() => handleNextPostToReview()}>Next Post</Button>}

                            </Col>
                            <Col>
                                <Col>

                                    {index < postsToReview.length - 1 &&
                                    <Button onClick={() => setIndex(index + 1)}>{"->"}
                                    </Button>
                                    }

                                </Col>
                            </Col>
                        </Row>


                    </Card>
                </div>}
                {endReached && postsToReview.length === 0 && signedInAsAdmin && noMorePosts()}

                <AdminToast setToasts={setToasts} toasts={toasts}/>
            </Container>
        </div>
    );
}

export default PageReview;