import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Button, Modal, Spinner} from "react-bootstrap";
import {ItemType, LoadingState} from "../constants/AppConstants";
import {ModalParams} from "./ReportScreen";
import {disableAllComments} from "../repository/CommentRepository";
import {disableAllPosts} from "../repository/PostRepository";


DisableAllModal.propTypes = {
    params: PropTypes.object,
    callback: PropTypes.func,
    onHide: PropTypes.func,
    show: PropTypes.bool
};

interface Props {
    params: ModalParams | null | undefined
    callback: Function
    onHide: Function
    show: boolean

}

function DisableAllModal(props: React.PropsWithChildren<Props>) {
    const {params, callback, onHide, show} = props;
    const [state, setState] = useState<LoadingState>(LoadingState.init);
    const currentType = params?.type === ItemType.post ? 'challenges' : 'comments';
    const handleClose = () => onHide();

    async function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        setState(LoadingState.loading);
        try {
            if (params?.type === ItemType.comment) {
                await disableAllComments(params.user.id);
            } else if (params?.type === ItemType.post) {
                await disableAllPosts(params?.user.id);
            }
            callback();
            setState(LoadingState.successful);
        } catch (error) {
            setState(LoadingState.error);
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
        >
            {state === LoadingState.loading ? <Modal.Body>
                <div className="d-flex justify-content-center">
                    <Spinner variant="primary" animation="border"/>

                </div>
            </Modal.Body> : <div>

                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Disable all {currentType}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {state === LoadingState.successful ? <p>
                            All {currentType} have been disabled!
                        </p> :
                        <p>
                            Are you sure you want to continue to disable all {currentType} for
                            user {params?.user.displayName} (@{params?.user.username})?
                            This is not reversible.
                        </p>
                    }
                    {state === LoadingState.error && <div className="d-flex justify-content-center">
                        <Alert variant="danger">Could not proceed with request</Alert>
                    </div>
                    }
                </Modal.Body>
                <Modal.Footer className={"justify-content-evenly"}>
                    <Button onClick={handleClose}> {state === LoadingState.successful ? 'Close' : 'Cancel'}</Button>
                    {state !== LoadingState.successful && <Button onClick={handleClick}>Ok</Button>}
                </Modal.Footer>
            </div>}
        </Modal>
    );
}

export default DisableAllModal;