import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "react-bootstrap";
import {ItemType} from "../constants/AppConstants";
import {Post} from "../interface/Post";
import {MyUser} from "../interface/MyUser";
import {MyComment} from "../interface/MyComment";

DisableButton.propTypes = {};

interface Props {
    onClick: Function,
    type: ItemType
    regarding: MyComment | Post | MyUser
}

function DisableButton(props: React.PropsWithChildren<Props>) {
    const {type, regarding, onClick} = props;

    function getTypeName(): string {
        switch (type) {
            case ItemType.comment:
                return 'Comment';
            case ItemType.post:
                return 'Post';
            case ItemType.user:
                return 'User';
            default:
                throw new Error(`Non-existent type in switch: ${type}`);
        }

    }


    return (
        <Button variant={regarding.disabled ? "success" : "danger"}
                onClick={() => onClick()}>
            {regarding.disabled ? "Enable" : "Disable"} {getTypeName()}
        </Button>
    );
}

export default DisableButton;