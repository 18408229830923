import React from 'react';
import PropTypes from 'prop-types';
import {default as _ReactPlayer} from 'react-player';
import {ReactPlayerProps} from "react-player/types/lib";
import "../styles/my_video_player.scss"
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

MyVideoPlayer.propTypes = {
    url: PropTypes.string
};

interface Props {
    url: string
}

function MyVideoPlayer(props: React.PropsWithChildren<Props>) {
    return (
        <div className="player-wrapper">
            <ReactPlayer
                         className='react-player'
                         height="100%"
                         width="100%"
                         controls={true}
                         playing={true} loop={true}
                         url={props.url}/>
        </div>
    );
}

export default MyVideoPlayer;