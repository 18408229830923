import React, {useState} from 'react';
import {Alert, Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {auth} from "../FirebaseSetup";


Login.propTypes = {};


interface SignInProps {
    email: string,
    password: string
}

function Login() {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [signInProps, setSignInProps] = useState<SignInProps>({email: '', password: ''})
    const [error, setError] = useState<string>('')

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setError('');
        if (signInProps.email.length === 0 || signInProps.password.length === 0) {
            setError('Fields are empty!');
        } else {
            setIsLoading(true);
            auth.signInWithEmailAndPassword(signInProps.email, signInProps.password)
                .catch((error) => {
                    const msg: string = error.message ?? '';
                    const subMsg = msg.substring(msg.indexOf(':') + 1, msg.indexOf('.') + 1);
                    setError(subMsg);
                })
                .finally(() => setIsLoading(false));
        }
    }


    return (
        <Row className="justify-content-center text-start">
            <Col lg={3}>
                {isLoading ? <div className="d-flex justify-content-center">
                        <Spinner variant={"primary"} animation={"border"}/>
                    </div>
                    :
                    <Form className="d-flex flex-column align-items-center" onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Enter email" value={signInProps.email}
                                          onChange={(e) => setSignInProps({...signInProps, email: e.target.value})}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" placeholder="Password" value={signInProps.password}
                                          onChange={(e) => setSignInProps({...signInProps, password: e.target.value})}/>
                        </Form.Group>
                        {error.length > 0 && <Alert variant="warning">{error}</Alert>}
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>}
            </Col>
        </Row>
    );
}

export default Login;