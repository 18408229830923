import {deleteData, getData, postData, putData} from "./client";
import {MyTrack} from "../interface/MyTrack";

const baseUrl = '/track'

export async function getTracks(limit: number, skip: number) : Promise<MyTrack[]>{
    try{
        return await getData(`${baseUrl}/get/${limit}/${skip}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function createTrack(track:MyTrack) : Promise<MyTrack>{
    try{
        return await postData(`${baseUrl}/create`, track);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function updateTrack(track:MyTrack) : Promise<MyTrack>{
    try{
        return await putData(`${baseUrl}/update`, track);
    }catch(e){
        console.log(e);
        throw e;
    }
}

export async function deleteTrackById(id: string) : Promise<boolean>{
    try{
        return await deleteData(`${baseUrl}/delete/${id}`);
    }catch(e){
        console.log(e);
        throw e;
    }
}
