import {Alert, Badge, Button, Card, Form, Modal} from "react-bootstrap";
import {ModalProps} from "react-bootstrap/Modal";
import CenterSpinnerCover from "./CenterSpinnerCover";
import {Dispatch, FormEvent, SetStateAction, useEffect, useState} from "react";
import {MyTrack} from "../interface/MyTrack";
import {uploadFile} from "../utils/funtions";
import {v4 as uuidv4} from 'uuid';
import {createTrack, deleteTrackById, updateTrack} from "../repository/TrackRepository";
import {getErrorMessage} from "../utils/error_handling";


interface Props {
    setTracks: Dispatch<SetStateAction<MyTrack[]>>
    track: MyTrack | null
}

function AddTrackModal(props: Props & ModalProps) {
    const {setTracks, track, ...modalProps} = props;
    const onHide = modalProps.onHide;
    const [title, setTitle] = useState<string>(track?.title ?? '');
    const [artist, setArtist] = useState<string>(track?.artist ?? '');
    const [genre, setGenre] = useState<string>(track?.genre.join(', ') ?? '');
    const [licence, setLicence] = useState<string>(track?.licence ?? '');
    const [image, setImage] = useState<File | null>();
    const [audio, setAudio] = useState<File | null>();
    const [isUploading, setIsBusy] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [remove, setRemove] = useState<boolean>(false);


    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        e.stopPropagation();
        setError(null);
        if (!track && (!audio || title.length < 1 || artist.length < 1 || genre.length < 1 || licence.length < 1)) {
            setError('Please fill fields');
            return;
        }

        setIsBusy(true);
        try {
            const id = track?.id ?? uuidv4();
            const imgUrl = (image ? await uploadFile(`track/${id}/image.${image.type.split('/')[1]}`, image) : null);
            const url = (audio ? await uploadFile(`track/${id}/track.mp3`, audio) : null);
            let trackToUpload: MyTrack = {
                id: id,
                createdAt: null,
                modifiedAt: null,
                title: title,
                artist: artist,
                licence: licence,
                imgUrl: imgUrl,
                url: url,
                genre: genre.split(',').map(e => e.trim()),
            };
            const result = track ? await updateTrack(trackToUpload) : await createTrack(trackToUpload);
            setTracks((oldState) => {
                const index = oldState.findIndex(e => e.id === id);
                if (index === -1) {
                    return [result, ...oldState];
                } else {
                    let list = [...oldState];
                    list[index] = result
                    return [...list];

                }
            });
            if (onHide) {
                onHide();
            }
        } catch (e: any) {
            const errorMsg = getErrorMessage(e);
            setError(errorMsg);
        } finally {
            setIsBusy(false);
        }
    }


    function loading() {
        return <div style={{height: "200px", overflow: "clip", borderRadius: '14px'}}>
            <CenterSpinnerCover/>
        </div>;
    }




    async function handleRemove() {
        if (!track) return;
        setIsBusy(true);
        try {
            const deleted = await deleteTrackById(track?.id);
            setTracks((oldState) => {
                let index = oldState.findIndex(e => e.id === track.id);
                let list = [...oldState];
                if (index > -1) {
                    list.splice(index,1);
                }
                return list;

            });
            if(onHide){
            onHide();
            }
        } catch (e: any) {
            const errorMsg = getErrorMessage(e);
            setError(errorMsg);
        } finally {
            setIsBusy(false);
        }
    }

    function form() {
        return <div>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add track
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control value={title} onChange={(e) => setTitle(e.target.value)}
                                      type="text" placeholder="Title of the track"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Artist</Form.Label>
                        <Form.Control value={artist} onChange={(e) => setArtist(e.target.value)}
                                      type="text"
                                      placeholder="Name of the artist"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Genre</Form.Label>
                        <Form.Control value={genre} onChange={(e) => setGenre(e.target.value)}
                                      type="text"
                                      placeholder="Genre of the track"/>
                        <Form.Text className="text-muted">
                            Separate genre with a "," comma. example "Electronic, synth-wave"
                        </Form.Text>
                        <div>
                            {genre.split(',').map(e=>  <Badge pill className={"me-1"} bg="primary">
                                {e.trim()}
                            </Badge>)}
                        </div>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Licence/info</Form.Label>
                        <Form.Control value={licence} onChange={(e) => setLicence(e.target.value)}
                                      type="textarea" placeholder="Add licence and info of the track"/>
                        <Form.Text className="text-muted">
                           Write with html tags to customize text and to add-links
                            <Alert variant={"info"}>
                                {'Track info with <a href="https://example.com/link">link</a>'}
                            </Alert>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Track image (Optional)</Form.Label>
                        <Form.Control
                            onChange={(e) => setImage((e.target as HTMLInputElement).files?.[0])} type="file"
                            accept=".png,.jpg,.jpeg,.webp"/>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Track Audio</Form.Label>
                        <Form.Control
                            onChange={(e) => setAudio((e.target as HTMLInputElement).files?.[0])} type="file"
                            accept={'.mp3'}/>
                    </Form.Group>
                    <Modal.Footer>
                        <div className='d-flex flex-column w-100'>
                            {error && <Alert variant={"warning"}>{error.toString()}</Alert>}
                            {remove ? <div className='d-flex justify-content-evenly'>
                                <Button onClick={() => setRemove(false)}>Cancel</Button>
                                <Button variant={'danger'} onClick={() => handleRemove()}>Continue</Button>
                            </div> : <div className='d-flex justify-content-evenly'>
                                {track != null &&
                                <Button onClick={() => setRemove(true)} variant="danger">Delete</Button>}
                                <Button onClick={() => {
                                    if (onHide) {
                                        onHide();
                                    }
                                }}>Cancel</Button>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                            </div>}
                        </div>
                    </Modal.Footer>

                </Form>
            </Modal.Body>

        </div>
    }


    return (
        <Modal
            {...modalProps}
            backdrop={'static'}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            {isUploading ? loading() : form()}

        </Modal>
    );
}

export default AddTrackModal;